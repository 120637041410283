@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @supports (height: 100dvh) {
    .interactive-height {
      height: 100dvh;
    }
  }

  @supports not (height: 100dvh) {
    .interactive-height {
      position: fixed;
      height: 100%;
      width: 100%;
    }
  }
}

@layer components {
  .paper-clip-path {
    clip-path: polygon(
      /* top left */ 0% 0%,
      /* top right */ 100% 0%,
      /* tear 1 */ 100% 8%,
      98% 9%,
      100% 10%,
      /* tear 2 */ 100% 45%,
      98% 46%,
      100% 47%,
      /* tear 3 */ 100% 52%,
      98% 53%,
      100% 56%,
      /* tear 4 */ 100% 78%,
      98% 79%,
      100% 80%,
      /* tear 5 */ 100% 85%,
      98% 86%,
      100% 87%,
      /* bottom right */ 100% 100%,
      /* bottom left */ 0% 100%,
      0% 95%,
      2% 94%,
      0% 93%,
      0% 80%,
      2% 75%,
      0% 70%,
      2% 65%,
      0% 60%,
      2% 55%,
      0% 50%,
      2% 45%,
      0% 40%,
      2% 35%,
      0% 30%,
      2% 25%,
      0% 20%,
      2% 15%,
      0% 10%,
      2% 5%
    );
  }
}

/* Styles for Plyr audio, used in EWI */
.plyr--audio .plyr__controls {
  background-color: #f7feff !important;
  border: 2px solid #b0edf5 !important;
  border-radius: 5em !important;
  padding: 5px !important;
}

.plyr__volume {
  min-width: auto !important;
}
